.custom-editor {
  position: absolute !important;
  width: 100% !important;
  height: 490px !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 9999 !important;
  overflow: hidden !important;
  border: 1px solid gray;
}

.custom-editor > div {
  width: 100%;
  height: 500px;
}
.custom-hidden-editor::after{
  content: 'Loading...';
  position: absolute;
  top: 0px;
  width: 100%;
  background: white;
  height: 490px;
  text-align: center;
  padding: 245px 0 0 0;
  font-size: 25px;
}
.editor-btns {
  position: absolute !important;
  bottom: -100px !important;
  left: 0px !important;
  z-index: 99999 !important;
  display: inline-flex;
  height: 80px;
}

.oc-fm--location-bar__item-name {
  max-width: 600px;
}

.wait-message {
  text-align: center;
  margin: 13% auto;
  font-size: 26px;
}
.editor-loading {
  position: absolute !important;
  width: 100% !important;
  height: 490px !important;
  top: -2% !important;
  left: 0 !important;
  z-index: 99999 !important;
  overflow: hidden !important;
  background: white;
  border: 1px solid gray;
  font-size: 25px;
}
.editor-loading > span {
  margin: 200px auto;
  display: block;
  text-align: center;
}
