
/* General card container styling */
.partners-cards-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  width: 75%;
  margin-top: 25px;
}

/* Individual partner card styling */
.partner-card {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.partner-card:hover {
  transform: translateY(-3px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

/* Card header styling */
.partner-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.partner-title {
  color: #0078d4;
}
.partner-subtitle {
  color: #666666;
  display: block;
  font-size: 13px;
  margin-top: 6px;
}
.partner-card-header:hover .partner-title {
  text-decoration: none;
}

.toggle-indicator {
  font-size: 14px;
  font-weight: normal;
  color: #555;
  transition: color 0.2s ease;
}

.toggle-indicator.open {
  color: #0078d4;
}

/* Feature table styling */
.partner-features {
  margin-top: 15px;
}

.features-table {
  width: 100%;
  border-collapse: collapse;
}

.features-table th,
.features-table td {
  border: 1px solid #e0e0e0;
  padding: 10px 15px;
  text-align: left;
}

.features-table th {
  background-color: #f9f9f9;
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

/* Status badges */
.feature-status-badge {
  display: inline-block;
  padding: 5px 10px;
  text-align: center;
  color: #ffffff;
  pointer-events: none;
}