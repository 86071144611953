.json-viewer {
  padding-bottom: 20px;
}
.json-viewer .json-inspector__line:after {
  right: 0;
}
.json-viewer .json-inspector__search {
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  padding: 5px 8px;
}