.packages-table #column-scope,
.packages-table #column-must_install,
.packages-table #column-auto_update,
.packages-table #column-is_active,
.packages-table #column-status,
.packages-table #column-type,
.packages-table #column-installed_version,
.packages-table #column-latest_version {
    margin: auto;
}
.packages-table #column-scope {
    padding-left: 0 !important;
}
.packages-table .cell-header {
    font-size: 13px;
}
.packages-table .cell-subheader {
    font-size: 11px !important;
}
.packages-table input,
.packages-table .auto-update-cell {
    margin: auto;
}
.packages-table .must_install-cell {
    font-size: 21px;
}
.packages-table .version-cell {
    margin-left: 5px;
}
.packages-table .latest_version-cell {
    font-size: inherit !important;
    margin: auto;
}
.packages-table .version-cell svg {
    margin-left: 3px;
    font-size: 18px;
    vertical-align: middle;
    padding-bottom: 3px;
}
/* update table */
.packages-table .update-status-cell,
.packages-table .active-cell {
    font-size: inherit !important;
    margin: auto;
}
/* history table */
.package-history-table .wps-icon,
.package-history-table .wps-bubble {
    margin: auto;
}
.package-history-table #column-source,
.package-history-table #column-scope,
.package-history-table #column-type,
.package-history-table #column-status {
    margin: auto;
}

/* common */
.package-history-table .scope-cell,
.packages-table .scope-cell,
.packages-table .must_install-cell {
    margin: auto;
    font-size: 19px;
}