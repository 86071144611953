.package-options {
    z-index: 1;
    margin: 50px 0px 0px 0px;
    padding: 50px 60px;
    border: 2px solid #eaeaea;
}

.package-options>.package-card-title {
    display: block;
    width: 100%
}

.package-options>.package-card-title>svg {
    margin: -10px 0px;
}

.package-options>.options {
    display: block;
    margin: 25px 0px 0px 68px;
    width: 100%;
}

.package-options>.options>div {
    display: inline-flex;
    width: 100%;
    margin-bottom: 25px;
}

.package-options>.options>.select-package-option {
    display: block;
    max-width: 80%;
}

.package-options>.options>div>label {
    margin: 0px 52px 0px 0px;
    font-weight: bold;
    width: 165px;
}

.package-options>.options>.select-package-option>label {
    display: inline-block;
    font-weight: bold;
}

.package-options>.options>.select-package-option>div {
    display: inline-block;
    width: 65%;
}

@media (max-width: 1160px) {
    .package-options>.options>.select-package-option>div {
        width: 50%;
    }
}

@media (max-width: 960px) {
    .package-options>.options>.select-package-option>div {
        width: 100%;
    }
}

.package-options>.plugin-info-select-parent {
    border: none;
    background: transparent;
}
