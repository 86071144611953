.color-orange {
  color: orange;
}
.color-blue {
  color: #3d6ece;
}
.color-green {
  color: #64c997;
}
.color-reddish {
  color: #af5554;
}
.color-purple {
  color: #9788e0;
}
.card-container {
  border-radius: 4px;
  padding: 10px;
  box-shadow: 1px 1px 10px 1px #ccc;
  margin: 18px 18px;
  display: block;
  overflow: auto;
  height: 100%;
  max-width: 380px;
}
.items-container {
  display: inline-flex;
  max-width: 350px;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.card-item {
  margin-bottom: 20px;
  max-width: 115px;
  width: 115px;
  display: inline-block;
  cursor: pointer;
}
.card-title {
  color: #2448AD;
  font-weight: bold;
  padding-top: 5px;
  text-align: center;
  width: 100%;
}
.icon-container {
  display: block;
  width: 70px;
  height: 70px;
  margin: 0 auto;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 1px 1px 10px 1px #ccc;
  margin-bottom: 10px;
}
.icon-container > svg {
  width: 100%;
  height: 100%;
}
.icon-container > svg > path{
  stroke: currentColor;
}
.item-name {
  display: block;
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
  font-size: 12px;
  color: #3469db;
  font-weight: bolder;
  min-height: 32px;
}
.card-item .disabled-color {
  cursor: not-allowed;
  opacity: 0.5;
}
