/* GENERIC */
.website-id-container > div > span,
.smtp-mailer-options .custom-tip {
  margin: 17px 0 0 10px;
}
.eventlog-time {
  font-weight: bold;
  display: inline-block;
  min-width: 92px;
}
/* TABLES */
#websites-table .profile-link {
  display: inline;
  position: relative;
  top: -2px;
  margin-right: 5px;
}

#websites-table #column-state,
#websites-table #column-status,
#websites-table #column-wp_version,
#websites-table #column-is_live,
#websites-table #column-php_version,
#websites-table #column-cdns,
#websites-table #column-env,
#websites-table #column-alert,
#websites-table #column-domains_ssl,
#websites-table #column-site_cached_by,
#websites-table #column-objectcache_config {
  margin: auto;
}
.alert-cell, .state-cell, .redis-cell, .cdn-cell, .ssl-cell, .cache-cell, .status-cell, .env-cell {
  margin: auto;
  font-size: 26px !important;
}
.ssl-cell, .cdn-cell, .alert-cell {
  font-size: 24px !important;
}
.cdn-cell.cdn-cloudflare  {
  font-size: 29px !important;
}
.state-cell {
  font-size: 21px !important;
}
.alert-cell svg {
  height: 1.1em;
  width: 1.1em;
  position: relative;
  top: 5px;
}
.env-cell {
  font-size: 13px !important;
}
.env-cell-dev, .env-cell-staging {
  font-size: 25px !important;
}
.env-cell-live {
  font-size: 21px !important;
}
.redis-cell {
  font-size: 33px !important;
}
.wp-version, .php-version {
  margin: auto;
}
/* STATUS HEADER */
.ws-profile-subheader {
  font-weight: bold !important;
  font-size: 12px !important;
}
.ws-status-icon svg, .ws-cdn-icon svg, .ws-alert-icon svg {
  font-size: 20px !important;
}
.ws-cdn-icon.cdn-cloudflare svg {
  font-size: 25px !important;
  position: relative;
  top: -2px;
}
.ws-state-icon svg {
  margin-top: 1px;
  font-size: 17px !important;
}
.status-left .wps-table-actions {
  margin-left: 0;
  padding: 0;
}
.status-left h2 .wps-bubble,
.status-left h2 .status-alert,
.status-left .status-dropdown {
  font-size: 21px;
  margin-left: 8px !important;
}
.status-left h2 .status-login,
.status-left h2 .status-alert {
  margin-left: 12px !important;
}
.status-left .status-refresh {
  font-size: 25px !important;
}
.status-left .wps-bubble svg {
  margin-right: 0;
}
.status-left .status-dropdown {
  width: 12px;
  height: 12px;
  margin-left: 6px;
}
.status-left .status-dropdown ul {
  display: block;
  top: 140%;
  left: 15px;
  padding: 5px;
}
.status-left .status-dropdown ul li {
  display: block;
  font-size: 14px;
  min-height: 35px;
  padding: 13px;
  margin-right: 0 !important;
}
.status-left .status-dropdown ul li svg {
  vertical-align: top;;
}
.status-right {
  padding-top: 5px;
}
.classic-dropmenu {
  margin-top: 3px;
}
.classic-dropmenu span svg {
  margin-right: 6px !important;
}
/* STAGING/LIVE */
.ws-env-selector {
  min-width: 180px;
}
.ws-env-selector .KIWI-button {
  border: none;
  margin: auto !important;
}
.ws-env-selector .ws-dropdown-sign {
  display: inline-block;
  width: 12px;
  margin-right: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  vertical-align: middle;
}
.ws-env-selector .live .ws-dropdown-sign,
.ws-env-selector .staging .ws-dropdown-sign {
  font-size: 24px;
}
.ws-env-selector .KIWI-option {
  font-size: 13px;
  padding: 7px 8px;
  color: #192634;
}
.ws-env-selector button.KIWI-option {
  background-color: #fff;
}
.ws-env-selector .KIWI-single-arrow {
  border-top: 5.5px solid #f6f2f2 !important;
  margin-left: 7px !important;
}
.ws-env-selector .ws-dropdown-item {
  width: 100%;
  text-align: left;
}
.ws-env-selector .ws-dropdown-content {
  position: relative;
  top: 1.5px;
}
.ws-env-selector .KIWI-option-list {
  width: 257px !important;
  overflow: hidden; 
  white-space: nowrap; 
  text-overflow: ellipsis;
}
.ws-dropdown-item.sync .ws-dropdown-content,
.ws-dropdown-item.create .ws-dropdown-content {
  top: 0;
}
/* SYNC STAGING */
.staging-checkbox {
  margin-top: 5px !important;
  margin-left: 5px !important;
}
.staging-diff-item {
  font-size: 12px;
}
.staging-review .wps-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
}
.staging-review .category-wrapper:not(:first-child) {
  margin-top: 15px;
}
.staging-review .staging-diff-item:not(:first-child) {
  margin-top: 5px;
}
.staging-extra-loading {
  font-size: 13px;
  margin-bottom: 10px;
}
/* BACKUP EXTRA INPUT */
.db-extra-input {
  margin-left: 30px;
}
.db-extra-input .select-input:last-child {
  margin-bottom: 15px;
}
.db-extra-input label:not(:first-child) {
  margin-top: 12px !important;
}
.db-extra-input label {
  margin-top: 0;
  font-size: 13px;
  text-decoration: underline;
}
.input-modal .db-extra-input {
  margin-top: 12px !important;
}
.backup-notes .ttl {
  margin-top: 5px;
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
}
/* STEPPERS */
.flags-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 20px 0px 0px 0px;
}
.flags-container .flag-item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flags-container .flag-item > img {
  max-width: 50px;
  max-height: 50px;
  object-fit: cover;
  margin: 5px 0px 15px 0px;
}
.flag-item svg {
  width: 50px;
  height: 42px;
  margin: 5px 0 15px 0;
  color: #2B68CD;
}
/* SMTP */
#smtp-mailer-form {
  max-width: 1000px;
}
#smtp-mailer-form fieldset {
  padding: 0px 16px 16px 16px;
}
#smtp-mailer-form fieldset > div > div {
  min-height: auto;
}
#smtp-mailer-form fieldset .checkboxes-row {
  margin-top: 10px;
}
#smtp-mailer-form fieldset .checkboxes-row label,
#smtp-mailer-form fieldset .checkboxes-row label:not(:first-of-type) {
  margin-bottom: 0;
  margin-top: 10px;
}
#smtp-mailer-form {
  margin-bottom: 50px;
}
.smtp-mailer-options {
  padding: 24px;
  max-width: 1000px;
}
.sendgrid-content .select-container {
  width: 60%;
}
#smtp-mailer-form .spf-box {
  padding: 18px;
}
#smtp-mailer-form .spf-box p:not(:last-child) {
  margin-bottom: 14px;
}
/* ANALYTIC CARDS */
.analytics-cards__website .analytics-card-values {
  margin: auto;
}
.analytics-cards__website  h4 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.analytics-card__custom {
  font-size: 12px;
}
.analytics-card__custom div {
  display: inline-block;
}
.analytics-card__title {
  font-weight: bold;
  min-width: 85px;
}
