#stripe-connect-customer .description {
  max-width: 70%;
  font-size: 16px;
}
#stripe-connect-customer .selection-box-btn,
#stripe-connect-customer #stripe-disconnect .stripe-disconnect-btn {
  max-width: 170px;
  margin: 25px 0;
}
#stripe-connect-customer #stripe-disconnect {
  font-size: 16px;
  margin: 0;
}
#stripe-connect-customer #stripe-disconnect span {
  margin: 0 0 6px 0;
  padding: 0 !important;
}
#stripe-connect-customer .stripe-info-item > span:nth-child(1) {
  min-width: 100px;
}
#stripe-connect-customer #stripe-disconnect .stripe-disconnect-btn {
  max-width: 170px;
  margin: 25px 0 0 15px;
}