.medium-fieldset fieldset {
  width: 75% !important;
}
.info-row {
  padding: 5px;
}
.info-label {
  font-weight: bold;
}
.info-line {
  display: inline-block;
  width: 100%;
  clear: both;
  font-size: 15px;
  margin: 5px;
  margin-left: 15px;
}
.checklist-row {
  padding: 5px;
}
.checklist-row svg {
  font-size: 16px;
  vertical-align: top;
}
.checklist-label {
  font-weight: bold;
}
.checklist-line {
  margin: 5px;
  margin-left: 27px;
  display: inline-block;
  width: 100%;
  clear: both;
  font-size: 13.5px;
}
.checklist-line > span {
  width: 90%;
  display: block;
  text-align: justify;
}
.step-title {
  font-size: 14px;
  max-width: 93%;
  text-align: justify;
  color: #2B68CD; /* color-primary */
}
.step-title {
  margin: 18px 0 0 7px;
}
.status-step button {
  margin-left: 7px;
  margin-top: 10px;
  padding: auto;
}
.stepper {
  width: 50%;
  margin: 0px 0 50px -6%;
}
.step-custom-form {
  max-width: 49% !important;
}
.step-copy-input {
  max-width: 580px;
  min-width: 580px;
}
.step-copy-icon {
  position: relative;
  right: -550px;
  bottom: 25px;
}
.status-step .notice span {
  display: inline;
}
.dns-manager-step {
  margin-left: -1.8% !important;
  margin-top: -0.7% !important;
}
.dns-manager-step .titlebar span {
  font-size: 14px !important;
}
.step-note {
  font-size: 15px;
  font-style: italic;
  color: #fc8121 !important;
}

.ns-steps {
  font-size: 15px;
  margin-left: 10px;
  margin-top: 25px;
}
.ns-steps p {
  line-height: 30px !important;
}
.ns-steps li {
  line-height: 2em;
}
.ns-record {
  white-space: nowrap;
  line-height: normal;
  display: inline-block !important;
  margin: 5px;
  min-width: 250px;
}
.ol-instructions li {
  margin-top: 3px;
}

/* dns setup */
#dns-setup-wizard .step-title {
  max-width: 90% !important;
}
#dns-setup-wizard .step-custom-form {
  max-width: 61% !important;
}
#dns-setup-wizard .notice {
  margin-left: 7px;
}
#dns-setup-wizard .rdt_Pagination {
  margin-bottom: 2px;
}

/* go live */
#go-live-wizard .ns-details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 30px;
  max-width: 580px;
}
#go-live-wizard .rdt_Pagination {
  margin-bottom: 2px;
}
#go-live-wizard .ns-details > div:first-child {
  text-align: center;
}
#go-live-wizard .ns-details > div {
  width: 50%;
}
#go-live-wizard .items-list > li {
  padding: 5px 0 !important;
  margin-bottom: 0px !important;
}
#go-live-wizard .items-list > li > span {
  width: auto !important;
}
#go-live-wizard .action-buttons button {
  margin: -5px 0 0 6px;
}
#go-live-wizard .dns-manager-step .action-buttons button:first-child {
  margin-left: 5px; /* align with delay propagation */
}
#go-live-wizard .go-live-stepper {
  width: 88%;
  margin-left: -2.5% !important;
}
#go-live-wizard .go-live-form {
  max-width: 70% !important;
}
#go-live-wizard .go-live-form .title-container > span > svg{
  width: 25px;
  height: 25px;
  margin-right: 7px;
  color: #a9c1d7;
}
#go-live-wizard .go-live-form .title-container{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
#go-live-wizard .go-live-form .content{
  padding-left: 32px;
  max-width: 600px;
  text-align: justify;
  margin: 5px 0 15px 0;
}
#go-live-wizard .db-replacements-step .notice span {
  display: inline;
}
#go-live-wizard input[type=text],
#go-live-wizard .select-container {
  width: 60% !important;
}
#go-live-wizard .cloudflare svg {
  width: 58px;
  height: 55px;
}

/* cdn proxy */
#cdn-proxy-wizard .action-buttons button {
  margin: -5px 0 0 7px;
}
#cdn-proxy-wizard .action-buttons button:first-child {
  margin-left: 5px; /* align with delay propagation */
}
#cdn-proxy-wizard .notice {
  margin-left: 7px;
}
#cdn-proxy-wizard .pending-validation label:first-child {
  margin-top: 10px !important;
}
#cdn-proxy-wizard .pending-validation label {
  margin-top: 3px !important;
}
#cdn-proxy-wizard .step-checkbox {
  margin-top: 10px;
  margin-left: 0px;
  min-height: 45px !important;
}
#cdn-proxy-wizard .step-title {
  margin-top: 0px;
  margin-bottom: 5px;
}
#cdn-proxy-wizard .notice span[display=block] {
  margin: 0;
}

/* git integration */
#connect-git-wizard .stepper {
  margin-left: 0;
  margin-bottom: 15px;
  width: 60%;
}
#connect-git-wizard .notice {
  margin-left: 7px;
}
#connect-git-wizard .notice > div {
  margin: 0;
  max-width: 75%;
}
#connect-git-wizard .step-custom-form {
  max-width: 60% !important;
}
#connect-git-wizard .step-title {
  margin-top: 0;
}
.confirmwh-label {
  margin-left: 7px;
  margin-top: 30px;
  font-size: 16px;
}
#confirmwh {
  margin-right: 9px;
  vertical-align: middle;
}
/* SSL */
#ssl-info .info-line {
  margin-top: 12px;
  margin-bottom: 10px;
}
#ssl-info .domains .info-line .domain:not(:last-child) {
  margin-bottom: 10px;
}
#ssl-info .domain-action button,
#ssl-info .domains .info-line .wps-icon,
#ssl-info .domains .info-line .domain-status,
#ssl-info .domains .info-line .domain-name {
  display: inline-block;
  margin-top: 4px;
}
#ssl-info .domains .info-line .domain-name {
  margin-left: 8px;
  position: relative;
  top: -3.5px;
}
#ssl-info .domains .info-line .domain-error {
  display: block;
}
#ssl-info .domains .info-line .domain-action button {
  margin: 0 0 0 5px;
  min-height: auto;
  position: relative;
  top: -1px;
  padding: 0px;
}
#ssl-info .domains .info-line .domain-status {
  margin-left: 20px;
  font-size: 14.5px;
  font-style: italic;
  margin-top: 0;
  color: red;
}
#ssl-info .ssl-status .wps-bubble {
  margin-left: 0px;
}
#ssl-info .ssl-status .error-msg {
  display: block;
  margin-top: 10px;
  font-size: 15px;
  color: red;
}
#go-live-wizard .ssl-step .action-buttons button:first-child {
  margin-left: 0 !important;
}
/* SMTP */

#go-live-wizard .smtp-mailer {
  padding: 0 !important; /* custom */
}
#go-live-wizard .smtp-integration {
  padding: 0 24px 12px 0 !important; /* sendgrid + others */
}
#go-live-wizard .smtp-mailer input[type=text],
#go-live-wizard .smtp-mailer .select-container {
  width: 100% !important;
}