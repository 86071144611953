span.desc-prefix {
  display: block;
  margin-bottom: 2px;
}
.desc-main svg {
  padding-top: 5px;
  margin-left: -3px;
}
.desc-main {
  text-align: justify;
}
.notice {
  margin-top: 15px;
}

.details-table {
  margin: 35px 0;
}
.details-table th {
  font-weight: bold;
  text-align: left;
  width: 30%;
}
.details-table th,
.details-table td {
  padding: 12px;
  vertical-align: top;
}
.details-table th,
.details-table td {
  border: 1px solid #f0f0f0;
}