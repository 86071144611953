li.modal-item {
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    margin-top: 3px;
}
li.modal-item span {
  margin-left: -6px;
}
.dynamic-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2048;
}
.modal-close-icon{
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 4px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  color: rgb(35,42,163);
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  font-size: 13px;
  cursor: pointer;
}
.dynamic-modal-body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 500px;
  max-width: 600px;
  width: auto;
  height: auto;
  min-height: 300px;
  max-height: 600px;
  background: white;
  border-radius: 15px;
  padding: 40px;
  overflow: auto;
}
.dynamic-modal-header {
  text-align: center;
}
.dynamic-modal-header > .dynamic-modal-header-title {
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 25px;
}
.dynamic-modal-content-info > p:first-child {
  margin-top: 5px;
}
.dynamic-modal-content-info > p {
  margin-bottom: 10px;
}
.dynamic-modal-delete-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: inherit !important;
  border-radius: 100rem !important;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(101deg, #ff0000, #ff8a5c);
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  box-shadow: 2px 1000px 1px #fff inset;
  padding: 8px 20px;
  margin: 0 auto;
}
.danger--light {
  color: red !important;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(101deg, #ff0000, #ff8a5c) !important;
}
.primary--light {
  color: inherit !important;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(101deg, #7083e2, #4fcceb) !important;
}
.warning--light {
  color: inherit !important;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(101deg, #fc8121, #ffc107) !important;
}
.success--light {
  color: inherit !important;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(101deg, #37df61, #48ff21) !important;
}
.gray--light {
  color: inherit !important;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(101deg, #9e9e9e, #9e9e9e) !important;
}
.dynamic-modal-delete-btn > svg {
  font-size: 16px;
}
.dynamic-modal-delete-btn > span {
  margin-left: 5px;
}
.dynamic-modal-header > svg {
  display: inline-block;
  color: #5096ff;
  font-size: 60px;
}
.dynamic-modal-spin > svg {
  animation-name: spin;
  animation-duration: 1300ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.warning svg {
  color: rgb(255, 103, 0) !important;
}
.success svg {
  color: #01c851 !important;
}
.primary svg {
  color: #2448ad !important;
}
.danger svg {
  color: #ff6969 !important;
}

.dynamic-modal-editor{
  width: 100% !important;
  min-height: 250px !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 9999 !important;
  overflow: hidden !important;
  border: 1px solid gray;
}
.dynamic-modal-editor > div {
  width: 100%;
  height: 500px;
}
.editor-body{
  min-width: 1000px !important;
  max-width: 1000px !important;
}

/* Table */

.large-dynamic-modal .dynamic-modal-body {
  min-width: 950px !important;
  max-width: 950px !important;
}

.dynamic-modal-body table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
.dynamic-modal-body table th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
  padding: 10px;
  text-align: left;
}
.dynamic-modal-body table td {
  padding: 10px;
  border-bottom: 1px solid #f2f2f2;
}
.dynamic-modal-body table tr:hover {
  background-color: #f2f2f2;
}