.connect-stripe-card .selection-box-btn {
  max-width: 140px;
  margin: 5% auto;
}
.connect-stripe-card .description {
  width: 60%;
  text-align: center;
  margin: 25px auto 0 auto;
  font-size: 16px;
}
.release-title {
  font-size: 17px;
  font-weight: bold;
}
.pie-summary {
  align-self: baseline;
  margin: 12px 0 0;
}
.pie-summary li {
  padding: 2px 0 !important;
  font-size: 13px;
  font-weight: bold;
}
.progress-table {
  width: 100%;
}
.progress-table td:first-child {
  width: 35%;
}
.progress-table td:nth-child(2) {
  width: 65%;
}
.progress-table tr {
  height: 35px;
}
.notification-content {
  display: block;
  height: 55px;
}
.notification-line {
  display: block;
}
.notification-text {
  margin-left: 6px;
  margin-top: 5px;
  font-size: 13.5px !important;
}
.notification-icon {
  vertical-align: middle !important;
}
.notification-icon > span {
  display: inline-block;
  font-size: 25px;
}
.progress-bar span {
  font-size: 13.5px !important;
}
.notifications-table {
  margin-top: 15px;
  width: 100%;
}
.notifications-table td:first-child {
  width: 93%;
}
.notifications-table td:last-child {
  width: 7%;
}
.notifications-table tr {
  height: 35px;
}
.recharts-responsive-container {
  margin-top: 40px;
  margin-left: -20px;
}
.no-data {
  margin: 40px auto;
}
.widget-notice {
  font-size: 12px;
  font-style: italic;
  text-align: center;
  margin: 0;
}
#news-table .notification-content, #changelog-table .notification-content {
  height: auto;
  margin-bottom: 10px;
}